import React, { useRef, useState } from "react";
import logo from "../../assets/logo.svg";
import bg from "../../assets/bg.svg";
import user from "../../assets/user.svg";
import passwardicon from "../../assets/passward.svg";
import text from "../../assets/text.png";
import "./LoginPage.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  riyologisticsloginApi,
  riyoBusinessloginApi,
} from "../../constants/api/APIs";
import base64 from "base-64";

const LoginPage = () => {
  const number = useRef();
  const password = useRef();
  const userType = useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let url = new URL("https://riyobusiness.riyorentitoverindia.in/");
  let url1 = new URL("https://riyologistics.riyorentitoverindia.in/");

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let data;
      if (userType.current.value === "riyo_business") {
        data = {
          number: number.current.value,
          password: password.current.value,
        };
      } else {
        data = {
          mobileNumber: number.current.value,
          password: password.current.value,
        };
      }

      let api = riyologisticsloginApi;

      if (userType.current.value === "riyo_business") {
        console.log(2323233);
        api = riyoBusinessloginApi;
      }
      const response = await axios.post(api, data);
      if (userType.current.value === "riyo_business") {
        let token = base64.encode(response.data.token);

        url.searchParams.append("searchuser", token);

        window.location.href = url.toString();
      } else {
        let token = base64.encode(response.data.token);

        url1.searchParams.append("searchuser", token);

        window.location.href = url1.toString();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("Invalid Credentials");
      console.log(error);
    }
  };

  return (
    <div className="Login-page">
      <div className="left">
        <img className="text" src={text} alt="" />
        <img className="bg" src={bg} alt="" />
      </div>
      <div className="right">
        <form className="login-form" onSubmit={handleLogin}>
          <img className="logo" src={logo} alt="" />
          <div className="welcome">Welcome!</div>
          <div className="select-field">
            <select name="user-type" className="select" ref={userType}>
              <option value="riyo_logistic">Riyo Logistic</option>
              <option value="riyo_business">Riyo Business</option>
            </select>
          </div>
          <div className="input-field">
            <img className="icon" src={user} alt="" />
            <input type="text" placeholder="Mobile Number" ref={number} />
          </div>
          <div className="input-field">
            <img className="icon" src={passwardicon} alt="" />
            <input type="password" placeholder="Password" ref={password} />
          </div>
          <button type="submit">Login</button>
          {loading && (
            <div className="loading-overlay">
              <i
                className="fa fa-spinner fa-spin"
                style={{ fontSize: "48px", color: "var(--primary)" }}
              ></i>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
