import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import LoginPage from "./components/LoginPage/LoginPage";
import '../src/constants/css/constant.css'
import './App.css';


const router = createBrowserRouter([{ path: '/', element: <LoginPage /> }])

function App() {
  return (<RouterProvider router={router} />);
}

export default App;
